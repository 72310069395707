.Controls {
  position: relative;
  width: 430px;
  border-right: 1px solid $cn_MediumGrey;
  background-color: $cn_LightBlue;
  padding-left: 5px;
  padding-right: 7px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 92%;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #BDBDBD;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.5);
  }

  h2 {
    text-transform: uppercase;
    font-size: $fz_Large;
    font-weight: bold;
    text-align: center;
    margin: $g_Gut;
  }

  h3 {
    text-transform: none;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
  }

  @include b('Wrapper') {
    display: flex;
    position: relative;
    justify-content: space-around;
    margin-top: 1em;
    @include m('ExtraMargin') {
      //margin-bottom: $g_Gut + 8px;
      margin-top: 0.3em;
    }
  }

  @include b('inWrapper') {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
  }
  
  @include b('Switcher') {
    position: relative;
    display: flex;
    align-self: center;
    //border: 2px solid $cn_Blue;
   // margin-bottom: $g_Gut;
  }

  @include b('item') {
    color: $cn_MediumGrey;
    cursor: pointer;
    transition: 100ms;
    transition-timing-function: linear;
  }

  @include b('Top-Switcher') {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0.3em 0 1.6em 0;
    font-size: $fz_Big;
    height: 40px;

    & .active {
      padding: 0.7em;
      font-size: 16px;
      font-weight: 600;
      color: $cn_Black;
      border-bottom: 2px solid $cn_Blue;
      transition: 100ms;
      transition-timing-function: linear;
    }

  }

  @include b('Second-Switcher') {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0em 0 1em 0;
    font-size: $fz_Small;
    height: 40px;

    & .active {
      padding: 0.7em;
      font-size: 15px;
      font-weight: 600;
      color: $cn_Black;
      border-bottom: 2px solid $cn_Blue2;
      transition: 100ms;
      transition-timing-function: linear;
    }

  }

  @include b('SwitcherAdress') {
    position: relative;
    display: flex;
    /* border: 1px solid $cn_Blue;
    border-radius: 1em; */
    // margin-bottom: $g_Gut;
    overflow: hidden;
  }

  @include b('SwitcherInfo') {
    font-family: $ff_RobotoCondensed;
    font-weight: bold;
    color: $cn_Black;
    font-size: $fz_Big;
    white-space: nowrap;
    padding: 0 0.5em;
    line-height: 1.4em;
    /* position: absolute;
    bottom: -$g_Gut - 8px;
    left: 0;
    right: 0; */
    text-align: left;
    margin: 0.6em 0;
    width: 100px;
  }

  @include b('Calculating') {
    border: 2px solid $cn_Blue;
    padding: 0 $g_Gut;
    height: $g_Mod-3;
    line-height: $g_Mod-3 - 4px;
    font-size: $fz_Big;
    color: $cn_Blue;
    font-weight: bold;
    margin-top: 1em;
    border-radius: 0.2em;
  }

  @include b('Header') {
    position: relative;
    height: $g_Mod-3;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  @include b('Logo') {
    position: relative;
    top: 3px;
    fill: $cn_Blue;
    width: 100px;
    height: $g_Mod-3;
  }

  @include b('Account') {
    height: 24px;
  }

  @include b('RemoveAll') {
    /* position: absolute;
    right: 0; */
  }

  @include b('AboutService') {
    margin-top: 1em;

    h3{
      text-align: center;
      font-size: 1rem;
    }
    ul{
      margin: 1em 0;
    }
    p {
      text-indent: 10px;
    }
  }
  @include b('FAQ_Header') {
    font-size: $fz_Big;
    font-weight: bold;
    cursor: pointer;
    margin-top: 1em;
  }
  @include b('d-none') {
    display: none;
  }
  @include b('activeFAQ') {
    display: block;
    padding: 0.1em 0;
  }

  @include b('Offer') {
    border: 1px solid #CFD8DC;
    background-color: #ECEFF1;
    border-radius: 0.4em;
    padding: 1em;
    margin: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 1.4rem;
      line-height: 1.6rem;
      text-align: center;
      margin-bottom: 0.5em;
    }

    ul li {
     margin-bottom: 0.2em; 
     margin-left: 1em;
     list-style-type: disc;
    }
  }

  @include b('IframeQuest') {
    min-height: 200px;
    overflow: hidden;
    //overflow-y: scroll;
    height: 400px;
    width: 500px;
  }
  @include b('inFrame') {
    min-height: 500px;
  }

  @include b('Copyright') {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 0 $g_Gut;
    margin: 1em 0;
    max-height: 80px;

    svg {
      width: 60px;
      height: auto;
    }
    .svg_ok {
      fill: #ee8208;
    }
    .svg_vk {
      fill: #4a76a8;
    }
    svg:hover {
      opacity: 0.8;
    }
  }

  @include b('Validation') {
    margin-bottom: $g_Mod-0;
    color: $cn_Red;
    text-align: center;
  }

  @include b('favoriteRoute'){
    border: 1px solid #CFD8DC;
    background-color: #ECEFF1;
    border-radius: 0.4em;
    padding: 1em;
    margin: 1em 0;
    .favoriteRouteStatus {
      cursor: pointer;
      font-weight: bold;
      margin-top: 0.5em;
      text-align: center;
    }
  }
}
@-moz-document url-prefix() {
  .Controls{
      overflow-y: auto;

      &:hover{
          padding-right: 9px;
      }
  }
}