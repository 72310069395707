.Input {
  @include inputBase();

  @include b('Checkbox') {
    position: relative;
    width: $g_Mod-2;
    height: $g_Mod-2;
    padding: $g_Mod-0;

    &:hover .Input {
      @include b('CheckboxMarker') {
        opacity: 0.9;
      }
    }
  }

  @include b('TextareaInput') {
    @include inputBase();
    flex: 1 0 auto;
    resize: vertical;
    line-height: $g_Mod-1 + 2px;
    padding: $g_Mod-0;
    //white-space: nowrap;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 200px;

    &:hover{
      overflow-y: auto;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -moz-overflow-scrolling: touch;
      -ms-overflow-scrolling: touch;
      -o-overflow-scrolling: touch;
      overflow-scrolling: touch;
      padding-right: 2px;
      
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #BDBDBD;
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.5);
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
  }

  @include b('DivInput') {
    @include inputBase();
    flex: 1 0 auto;
    line-height: $g_Mod-1 + 2px;
    padding: $g_Mod-0;
    white-space: nowrap;
  }

  @include b('CheckboxInput') {
    height: $g_Mod-1;
    width: $g_Mod-1;
    border: 1px solid $cn_Blue;
    background-color: $cn_White;
  }

  @include b('CheckboxHidden') {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    height: 100%;
    width: 100%;
  }

  @include b('CheckboxMarker') {
    height: $g_Mod-0;
    width: $g_Mod-0;
    margin: 3px;
    background-color: $cn_Blue;
    visibility: hidden;

    @include m('Enabled') {
      visibility: visible;
    }
  }
}