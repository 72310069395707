.ControlsPersonalArea {
  display: flex;
  flex-direction: column;
  //align-items: center;
  //opacity: 0.5;

  .warning {
    margin: 1em 0;
    padding: 1em;
    text-align: center;
    border: 1px solid red;
    font-size: 16px;
  }

  h3 {
    font-size: $fz_Large;
    text-align: center;
    margin-bottom: 0.3em;
  }

  label {
    margin: 1em 0 0.3em 0;

    span {
      display: inline-block;
      width: 80px;
    }
  }

  input {
    outline: none;
    background-color: #f5f5f5;
    border-radius: 4px;
    border: none;
    min-width: 48px;
    height: 36px;
    line-height: 34px;
    font-size: 13px;
  }

  @include b('Wrapper') {
    display: flex;
    position: relative;
    justify-content: space-around;
    margin-top: 1em;
  }

  @include b('WorkTimeLabel') {
    display: block;
    margin: 4px 0;
    color: $cn_Blue;
    font-weight: bold;
  }

  @include b('WorkTime') {
    display: flex;
    
    & > * {
      margin-right: $g_Mod-0;
    }
  }

  @include b('RoutesWrapper') {
   margin: 2em 0 0 0;
  }

  @include b('RoutesButtonWrapper') {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include b('RouteButton') {
    margin-left: 0.5em;
    font-size: larger;
    font-weight: bold;
  }

  @include b('EditButton') {
    cursor: pointer;
    top: 0;
    height: $g_Mod-2 + 4px;
    width: $g_Mod-2 + 4px;
    padding: 8px;
    fill: $cn_MediumGrey;

    &:hover {
      opacity: 0.5;
    }

    @include m('Remove') {
      right: 0;
    }
  }
}