$cn_White: #FFF;
$cn_Black: #424242;
$cn_Transparent: rgba(255,255,255,0);
$cn_Blue: #2196F3;
$cn_Blue2: #90CAF9;
$cn_LightBlue: #fff;
$cn_Grey: #555;
$cn_MediumGrey: #bfbfbf;
$cn_Medium2Grey: #4d4d4d;
$cn_LightGrey: #DDD;
$cn_Red: #FF5722;
$cn_Green: #8BC34A;
$cn_LightGreen: #DCEDC8;
$cn_Orange: #FFC107;
