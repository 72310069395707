.ControlsTextEditor {
  min-height: 140px;
  display: flex;
  flex-direction: column;

  @include b('AdreeHint') {
    margin: 0.3em 0 0 0.5em;
  }

  @include b('Row') {
    font-family: $ff_OpenSans;
    font-weight: normal;
    height: $g_Mod-1;
    line-height: $g_Mod-1;

    @include m('NoCoords') {
      color: $cn_Red;
    }
  }

  @include b('Wrapper') {
    display: flex;
    position: relative;
    justify-content: space-around;
    margin: 1em 0;
  }
}