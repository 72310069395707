@mixin shadowBase {
  box-shadow: 5px 5px 0px rgba(0,0,0,0.4)
}

@mixin position($val) {
  left: $val;
  right: $val;
  top: $val;
  bottom: $val;
}

@mixin buttonBase {
  cursor: pointer;
  height: $g_Mod-2;
  align-self: center;
  background-color: $cn_Blue;
  color: $cn_White;
  user-select: none;
  border: none;
  white-space: nowrap;
  padding: 0 $g_Mod-0;
  font-size: $fz_Big;
  font-family: $ff_RobotoCondensed;
  fill: $cn_White;

  &:hover {
    background-color: rgba($cn_Blue, 0.9);
    fill: $cn_White;
  }
}

@mixin buttonBaseIcon {
  cursor: pointer;
  font-family: $ff_RobotoCondensed;
  font-size: 15px;
  padding: 2px 5px;
  position: relative;
  fill: $cn_White;
  background: $cn_Blue; 
  color: $cn_White;

  &:hover {
    background: rgba($cn_Blue, 0.9); 
  }
}

@keyframes hintFadeIn {
  0% { opacity: 0; }
  80% { opacity: 0; }
  100% { opacity: 1; }
}

@mixin scrollBarStyle {
  overflow: hidden auto;
  padding-right: 12px;
  margin-right: -12px;
  width: calc(100% + 12px);

  &::-webkit-scrollbar {
    width: $g_Mod-0;
  }
  
  &::-webkit-scrollbar-track {
    background-color: $cn_Transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: $cn_Blue;
  }
}

@mixin inputBase {
  outline: none;
  background-color: #f5f5f5;
  //border: 1px solid $cn_Blue;
  border-radius: 4px;
  border: none;
  min-width: $g_Mod-3;
  width: 100%;
  height: $g_Mod-2 + 4px;
  line-height: $g_Mod-2 + 2px;
  font-size: $fz_Small;
  padding: 0px $g_Mod-0;

  &::placeholder {
    font-style: italic;
    color: $cn_Grey;
    text-transform: lowercase;
  }

  &::-ms-clear {
    display: none;
  }

  &::-webkit-clear-button {
    display: none;
  }
}