// BEM styling tools

$bemElementSplitter: '__';
$bemModifierSplitter: '_';
$bemDoubleDashSplitter: '--';

@mixin b($element) {
  &#{$bemElementSplitter}#{$element} {
    @content;
  }
}

@mixin m($modifier) {
  &#{$bemModifierSplitter}#{$modifier} {
    @content;
  }
}

@mixin dd($modifier) {
  &#{$bemDoubleDashSplitter}#{$modifier} {
    @content;
  }
}