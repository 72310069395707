.ModalWindow {
  position: fixed;
  @include position(0);
  z-index: 999;

  @include b('Overlay') {
    position: absolute;
    @include position(0);
    background-color: rgba(black, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1;
  }

  @include b('WindowDynamicIndent') {
    margin-top: $g_Gut;
    flex: 0 1 $g_Mod-4;
    z-index: 2;
  }

  @include b('Window') {
    @include shadowBase();
    background-color: $cn_LightBlue;
    padding: $g_Gut * 2;
    margin-bottom: $g_Gut;
  }
}