@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700|Roboto+Condensed:400,700&subset=cyrillic');

$ff_RobotoCondensed: 'Roboto Condensed', sans-serif;
$ff_OpenSans: 'Open Sans', sans-serif;

$fz_Tiny: 12px;
$fz_Small: 13px;
$fz_Big: 14px;
$fz_Large: 18px;

$f_TextBase: $fz_Small $ff_OpenSans;