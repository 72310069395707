.ControlsResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;

  @include b('Statistics') {
    margin-bottom: $g_Gut;
  }

  @include b('Descriprion') {
    margin-bottom: 1em;
    text-align: center;
    font-style: italic;
  }

  @include b('Param') {
    &:not(:last-of-type) {
      margin-right: $g_Gut;
    }
  }

  @include b('RouteControls') {
    display: flex;
    flex-wrap: wrap;

    @include m('TopMargin') {
      margin-top: 10px;
    }

    > .Button:not(:last-child) {
      margin-right: 5px;
    }
  }

  @include b('NavigateButton') {
    .Button__Icon {
      padding: 5px;
    }
  }
  .Button_DisabledIconed {
    background: #DDD;
    cursor: pointer;
    fill: #2196F3;
  }
}