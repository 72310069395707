.MapContextMenu {
  z-index: 500;
  background: $cn_White;
  border: 1px solid $cn_LightGrey;

  &>div {
    padding: 0.3em 0.5em;
    cursor: default;

    &:not(:last-child) {
      border-bottom: 1px solid $cn_LightGrey;
    }

    &:hover {
      background-color: $cn_LightGrey;
    }
  }
}