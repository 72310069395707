.LeafletMapMarker {
  fill: $cn_Blue;
  width: $g_Mod-3 !important;
  height: $g_Mod-3 !important;
  top: -42px;
  left: -18px;

  @include m('Courier') {
    @include m('1') {
      fill: cornflowerblue;
      color: cornflowerblue;
    }
    @include m('2') {
      fill: firebrick;
      color: firebrick;
    }
    @include m('3') {
      fill: seagreen;
      color: seagreen;
    }
    @include m('4') {
      fill: violet;
      color: violet;
    }
    @include m('5') {
      fill: tomato;
      color: tomato;
    }
    @include m('6') {
      fill: orange;
      color: orange;
    }
    @include m('7') {
      fill: indigo;
      color: indigo;
    }
    @include m('8') {
      fill: chocolate;
      color: chocolate;
    }
    @include m('9') {
      fill: gold;
      color: gold;
    }
    @include m('0') {
      fill: maroon;
      color: maroon;
    }
  }

  @include b('VisitedStatus') {
    border-radius: 9px;
    position: absolute;
    width: 14px;
    height: 14px;
    top: -1px;
    right: 2px;
    border: 1px solid #0b171c;
    transform: rotate(45deg);
    display: none;

    @include m('InWork') {
      display: block;
      background-color: $cn_Orange;
    }

    @include m('Success') {
      display: block;
      background-color: $cn_Green;
    }

    @include m('Failed') {
      display: block;
      background-color: $cn_Red;
    }
  }

  @include m('Failed') {
    & * {
      color: $cn_Red !important;
      fill: $cn_Red;
    }
  }

  @include m('Highlighted') {
    & * {
      color: $cn_Green !important;
      fill: $cn_Green;
    }
  }

  @include b('Position') {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 32px;
    font-size: 19px;
    // color: $cn_Blue;
    font-weight: bold;
  }

  @include b('NoPosition') {
    position: absolute;
    left: $g_Mod-1;
    top: $g_Mod-0;
    width: $g_Mod-1;
    height: $g_Mod-1;
    border-radius: $g_Mod-0;
    background-color: $cn_Blue;
  }

  @include b('Tooltip') {
    border-radius: .5em;
    padding: 8px 21px;
    transition: opacity 0.3s ease-out;
    color: $cn_Black;
    background-color: #fff;
    border: 2px solid $cn_Green;
    font-family: $ff_RobotoCondensed;
    font-size: 1em;
    box-shadow: none;

    & ::after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      bottom: -6px;
      left: 50%;
      margin-left: -8px;
      border-top-color: $cn_Green;
      border-top-style: solid;
      border-top-width: 6px;
    }
  }

  @include b('ToolTip_Address') {
    font-weight: 800;
  }
}