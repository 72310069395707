.ConfirmDialog {
  @include b('Message') {
    margin-bottom: $g_Gut;
    text-align: center;
    max-width: 420px;
  }

  @include b('Buttons') {
    display: flex;
    justify-content: center;

    & > *:not(:last-of-type) {
      margin-right: $g_Gut;
    }
  }
}