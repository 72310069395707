@import 'src/base/index';
@import 'src/common/index';

@import 'src/modules/map/index';
@import 'src/modules/controls/index';

@import 'src/modules/others/others';
@import 'src/common/styles/Mobile.scss';

html {
  overflow: hidden;
}

html, body, #root {
  height: 100%;
}

body {
  font: $f_TextBase;
  line-height: $g_Mod-1;
  color: $cn_Black;
}

h1, h2, h3, h4 {
  font-family: $ff_RobotoCondensed;
  color: $cn_Grey;
}

a {
  text-decoration: underline;
  color: $cn_Blue !important;

  &:hover {
    text-decoration: none;
  }
}

.App {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.onetow {
  color: red;
}