.Input__Options {
  list-style: none;
  margin: 1em 0 1em 0;
  border-top: 2px solid $cn_Blue;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;

  & li {
    padding: 0.4em 0 0.4em 0.2em;
    cursor: pointer;
    background-color: white;
  }

  & li:hover {
    background-color: $cn_LightGrey;
  }

}

.Input__Options:before {
  content: '';
  display: block;
  position: absolute;
  border: 7px solid transparent;
  border-bottom: 7px solid $cn_Blue;
  top: 35px;
  left: 42%;
}

.siteHeader {
  width: 100%;
  max-height: 50px;
  border-bottom: 1px solid $cn_MediumGrey;
  background-color: $cn_LightBlue;
  padding: 0.3em;
  display: flex;
  justify-content: space-around;
  align-items: center;

  h1 {
    font-size: 1.7em;
    line-height: 0.8em;
    text-align: center;
    width: 100%;
  }

  & svg {
    max-width: 100px;
    fill: $cn_Medium2Grey;
  }
  .nav{
    float:right;
    width: 100%;
  }
    
  .nav ul{
    list-style:none;
    padding:0px;
    }
  .nav ul.toggle{
    display:none;
    visibility:hidden;
    height:0px;
    overflow:hidden;
    margin-bottom: 0;
    }
    
  .nav ul li{
    display:inline-block;
    margin:0 5px;
    }
 
  .nav ul li:last-child{
    margin:0 0 0 23px;
    }
    
  .nav ul li a{
    display:block;
    font-size:14px;
    color:#222222;
    font-family:'Fairymuffinroundpop';
    text-decoration:none;
    text-transform:uppercase;
    font-weight: 600;
    transition:all 0.2s ease-in-out;
    -moz-transition:all 0.2s ease-in-out;
    -webkit-transition:all 0.2s ease-in-out;	
    }
  .nav ul li a:hover{
    color:#49b5e7;
    }
}

.BtnClose {
  width: 100px;
  float: right;
  margin: 0 10px 10px 0;
}

.FirstQuiz {

  & h3 {
    font-size: 1.3em;
  }

  & p {
    margin: 0.3em 0;
    font-size: 1.2em;
  }

  & label {
    margin: 0.3em 0;
    font-size: 1.1em;
  }

  & input[type='radio'] {
    margin-right: 0.2em;
  }

  & input.Input {
    margin: 0.5em 0;
  }

  & .textarea {
    width: 100%;
    min-height: 100px;
    padding: 0.5em;
    margin: 1em 0;
  }

  & .QuizBtnGroup {
    display: flex;
    justify-content: space-around;
  }

  & .radio input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;
  }

  & .radio__text {
    position: relative;
    padding: 0 0 0 25px;
    cursor: pointer;
    margin: 0.4em 0;
  }

  & .radio__text:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #CDD1DA;
    border-radius: 50%;
    background: #FFF;
  }

  & .radio__text:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $cn_Blue;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .5);
    opacity: 0;
    transition: .2s;
  }

  & .radio input:checked+.radio__text:after {
    opacity: 1;
  }

  & .radio input:focus+.radio__text:before {
    //box-shadow: 0 0 0 3px rgba(255,255,0,.7);
  }

  & .endWindowBtn {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }
}