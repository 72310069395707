.ControlsManualEditor {
  display: flex;
  flex-direction: column;
  // align-items: center;

  @include b('AddressList') {
    @include scrollBarStyle;
    min-height: 96px;
   // max-height: calc(100vh - 586px);
    margin-bottom: $g_Gut;
  }
  @include b('LimitMassage') {
    @include scrollBarStyle;
    text-align: center;
    margin: 0.5em 0;
  }
  @include b('BtnLimitMassage') {
    @include scrollBarStyle;
    margin: 0.5em 0;
    margin-left: auto;
    margin-right: auto!important;
    max-width: 205px;
    padding-left: 10px;
    & .Button__Title{
      padding-left: 20px;
    }
  }
  
}