@media screen and (max-device-width: 720px) {
    html, body, body #root {
        height: auto;
        overflow-y: scroll;
    }

    .siteHeader{
        height: auto;
        max-height: none;
        flex-wrap: wrap;
        align-content: flex-start;
        z-index: 10;
        & svg {
            max-height: 50px;
        }
        & h1 {
            font-size: 1.5em;
            margin-bottom: 0.3em; 
        }
    }
    .Controls{
        width: 100%;
        border-right: none;
        transition: transform .2s ease-in-out,opacity .2s ease-in-out,visibility .2s ease-in-out,-webkit-transform .2s ease-in-out;
    }
    .MobileSwitch-on{
        .Controls {
            
        }
        .LeafletMap{
            //display: none;
            //visibility: hidden;
        }
    }
    .MobileSwitch-off{
        .Controls{
            -webkit-transform:translateX(-100%);
            transform:translateX(-100%);
            
            transition: transform .2s ease-in-out,opacity .2s ease-in-out,visibility .2s ease-in-out,-webkit-transform .2s ease-in-out;
            z-index: 1;
            visibility:hidden;
            pointer-events:none;
        }
        .LeafletMap{
            height: 100%;
            width: 100%;
            position: fixed;
        }
    }
    
    .titelh1{
        order: 1;
    }
    .Controls__SwitcherAdress{
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .Button_Default{
        padding: 14px 0;
        width: 100%;
    }
    .Button_Disabled{
        padding: 18px 0;
        width: 100%;
    }
    .BtnMobile {
        position: absolute;
        bottom: 9px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        height: 40px;
        margin: 0;
        padding: 0 16px;
        border-radius: 20px;
        background-color: #198cff;
        box-shadow: 0 2px 6px 0 rgba(0,0,0,.2);
        -webkit-flex-shrink: 0;
        flex-shrink: 0;
        pointer-events: auto;
        color: #fff;
        z-index: 450;
        width: 200px;
    }
    .ControlsResults__Statistics {
        display: flex;
        flex-wrap: wrap;
    }
    .ModalWindow__WindowDynamicIndent {
        margin-top: 5px;
        flex: 0 1 0px;
    }
    .Toastify__toast-container {
        margin-bottom: 4em;
    }
    .Controls__Copyright {
        margin-bottom: 9em;
    }
    .ModalWindow__Window {
        overflow-y: scroll;
    }

    .QuizBtnGroup{
        .Button_Default{
            padding: 14px 0;
            width: 40%;
            text-align: center;
        }
    }
  }
@media screen and (max-device-width: 390px) {
  .Controls__inWrapper{
    flex-wrap: wrap;
    }
    .Controls {

    @include b('IframeQuest') {
        min-height: 370px;
        overflow: hidden;
        overflow-y: scroll;
       // height: 370px;
        width: 320px;
      }

      @include b('inFrame') {
        min-height: 500px;
      }
    }
}