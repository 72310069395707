.LoginForm {
  max-width: 320px;

  @include b('Validation') {
    margin-bottom: $g_Mod-0;
    color: $cn_Red;
    text-align: center;
  }

  @include b('Logo') {
    height: $g_Mod-3;
    margin-top: -$g_Mod-0;
    fill: $cn_Blue;
  }

  @include b('Title') {
    text-align: center;
    margin-bottom: $g_Gut;
    margin-top: $g_Mod-0;
    color: $cn_Grey;
  }
  @include b('TitleLogin') {
    text-align: center;
    margin-bottom: $g_Gut;
    margin-top: $g_Mod-0;
    color: $cn_Grey;
    min-width: 250px;
  }

  @include b('FieldsBox') {
    animation-name: fadeIn;
    animation-fill-mode: both;
    animation-duration: .5s;
  }

  @include b('Input') {
    width: 100%;
    margin-bottom: $g_Gut;

  }

  @include b('Submit') {
    text-align: center;

    & > input {
      width: 120px;
    }
  }

  @include b('Extra') {
    display: flex;
    justify-content: space-between;
  }

  @include b('Splitter') {
    margin: $g_Gut 0px;
    border-bottom: 1px solid $cn_Blue;
  }

  @include b('Info') {
    text-align: center;
    margin-bottom: $g_Gut;
    font-weight: bold;
  }
}