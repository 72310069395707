.Button {
  position: relative; 

  @include m('Iconed') {
    @include buttonBaseIcon;
    display: flex;
    border-radius: 0.2em;
    margin: 0.2rem;
  }

  @include m('Small'){
    background-color: #00BCD4;
    &:hover {
      background-color: rgba(#00BCD4, 0.7);
    }
  }

  @include b('Icon') {
    height: $g_Mod-2;
    width: $g_Mod-2;
    pointer-events: none;
    padding: 2px;
    margin-right: -4px;

    @include m('NoTitle') {
      height: $g_Mod-2;
      width: $g_Mod-2;
      margin-right: 0;
    }

    @include m('Small') {
      height: 28px;
      width: 28px;
      padding: 0;
    }

    @include m('Smaller') {
      height: 20px;
      width: 20px;
      padding: 0;
    }
  }

  @include b('Title') {
    padding: 0 $g_Mod-0;
    line-height: $g_Mod-2;
    //text-transform: uppercase;
    
    @include m('Iconed') {
      padding-right: $g_Gut;
    }
  }

  @include m('Default') {
    @include buttonBase;
    padding: 14px 62px;
    align-self: flex-end;
    border-bottom: 1px solid $cn_MediumGrey;
    border-top-right-radius: 0.2em;
    border-top-left-radius: 0.2em;
    .Button__Title{
      line-height: 6px;
    }
    
  }

  @include m('Warning') {
    background-color: $cn_Orange;
    &:hover {
      background-color: rgba($cn_Orange, 0.9);
    }
  }

  @include m('Danger') {
    background-color: $cn_Red;
    &:hover {
      background-color: rgba($cn_Red, 0.9);
    }
  }

  @include m('Confirm') {
    background-color: $cn_Green;

    &.Button_DisabledIconed, &.Button_DisabledIconed:hover {
      background: $cn_LightGrey;
      cursor: auto;
      fill: $cn_Blue;
    }

    &:hover {
      background-color: rgba($cn_Green, 0.9);
    }
  }

  @include m('Unimportant') {
    background-color: $cn_MediumGrey;
    &:hover {
      background-color: rgba($cn_MediumGrey, 0.9);
    }
  }

  @include m('Disabled') {
    @include buttonBase;
    cursor: default;
    background-color: transparent !important;
    color: $cn_Blue;
    padding: 18px 62px;
    border-top: 1px solid $cn_MediumGrey;
    border-right: 1px solid $cn_MediumGrey;
    border-left: 1px solid $cn_MediumGrey;
    border-top-left-radius: 0.2em;
    border-top-right-radius: 0.2em;
    &:hover {
      background-color: transparent !important;
    }
  }

  @include m('DisabledIconed') {
    cursor: default;
    color: $cn_Blue;
    background-color: #fff;
    fill: $cn_Medium2Grey;
    &:hover {
      background-color: transparent;
      fill: $cn_Blue;
    }
    &.Button_Small, &.Button_Small:hover {
      cursor: no-drop;
      fill: $cn_Blue;
      background-color: #fff;
    }
  }
  @include m('NewCheck') {
    cursor: pointer;
    background-color: $cn_Medium2Grey;
    fill: #fff;
    &:hover {
      background-color: $cn_Medium2Grey;
      fill: #fff;
    }
  }
  @include m('NewCheckDis') {
    cursor: pointer;
    background-color: #fff;
    fill: #4d4d4d;
    &:hover {
      background-color: #fff;
      fill: #4d4d4d;
    }
  }

  @include m('WithSpinner') {
    border-bottom: 1px solid $cn_MediumGrey !important;
    padding: 14px 62px;

    .Button__Spinner {
      display: inline-block;
    }
  }

  @include m('disabled') {
    border-bottom: 1px solid $cn_MediumGrey !important;
    padding: 14px 62px;
    cursor: not-allowed;
  }

  @include b('Spinner') {
    display: none;
    position: relative;
    line-height: 16px;
    margin-top: -12px;
    padding-left: 20px;

    &>img {
      width:16px;
      position:absolute;
      left:0;
    }
  }
}
.ButtonDisabled {
  cursor: no-drop;
  color: $cn_Blue;
  background-color: transparent;
  fill: $cn_Blue;
  border: 2px solid $cn_Red;
}
.NewBtn{
  margin-right: 10px;
  border-radius: 50%;

  cursor: pointer;
  font-family: $ff_RobotoCondensed;
  font-size: $fz_Big;
  padding: 4px;
  position: relative;
 // fill: #4d4d4d;
  //background: #4d4d4d;
  color: $cn_White;
}
.BtnMobile {
  display: none;

}

.SwitcherAdress_Buttons .Button_Disabled {
  padding: 18px 32px;
}
.SwitcherAdress_Buttons .Button_Default {
  padding: 14px 32px;
}