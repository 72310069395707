.running-line {
  position: fixed;
  bottom: 0;
  left: 430px;
  width: calc(100% - 430px);
  z-index: 999;
  overflow: hidden;
  padding: 8px;
  background: $cn_White;
  border-top: 1px solid $cn_MediumGrey;
  &:hover & {
    &__inner-wrapper {
      animation-play-state: paused;
    }
  }
  &__inner-wrapper {
    display: table-cell;
    white-space: nowrap;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 100s;
    animation-play-state: running;
    animation-name: move;
    cursor: default;
    user-select: none;
  }
  &__item {
    display: inline-flex;
    flex-direction: column;
    margin-right: 16px;
  }
  &__user-name {
    color: $cn_Blue;
  }
  &__subtitle {
    color: $cn_Black;
  }
  @media (max-width: 1024px) {
    display: none;
  }
}

@keyframes move {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}