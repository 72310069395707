.LeafletMap {
  flex-grow: 1;

  & > * {
    height: 100%;
  }

  @include b('Map') {
    opacity: 1;
    
    @include m('Transparent') {
      opacity: 0.4 !important;
    }
  }

  @include b('Polyline') {
    stroke-dasharray: 2px 12px;
    stroke-linecap: round;
    stroke-width: 2px;
    fill: none;

    @include m('Path') {
      stroke-dasharray: none;
    }

    @include m('Track') {
      cursor: grab;
      stroke-dasharray: none;
    }

    @include m('Dimmed') {
      display: none;
      pointer-events: none;
    }
  }

  @include b('PolylineShadow') {
    stroke-dasharray: 2px 12px;
    stroke-linecap: round;
    stroke-width: 3.4px;
    stroke: $cn_Black;
    stroke-opacity: 0.9;
    fill: none;

    @include m('Path') {
      stroke-dasharray: none;
    }

    @include m('Track') {
      stroke: $cn_White;
      stroke-dasharray: none;
      stroke-width: 4.4px;
      cursor: grab;
    }

    @include m('Dimmed') {
      display: none;
    }
  }

  @include b('Route') {
    stroke-linecap: round;
    stroke-width: 6px;
    // stroke: $cn_Blue;
  }

  @include b('MapHeader') {
    max-height: 50px;
    border-bottom: 1px solid $cn_Blue;
    background-color: $cn_LightBlue;
    padding: 1em;
    display: table-caption;
    h1{
      font-size: 2em;
      line-height: 0.8em;
      text-align: center;
    }
  }
}
