.TextLink {
  color: $cn_Blue !important;
  text-decoration: none;
  display: inline;
  align-self: center;
  text-transform: uppercase;
  margin: 0 0.4em;

  &:hover {
    text-decoration: underline;
  }

  @include m('Disabled') {
    text-decoration: none;
    color: $cn_Grey !important;
  }
}