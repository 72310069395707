.switch-btn {
    display: inline-block;
    width: 40px;
    height: 20px;
    border-radius: 19px;
    background: #bfbfbf;
    z-index: 0;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    position: relative;
    transition-duration: 300ms;
    margin: 0.6em 0;
}
.switch-btn::after {
    content: "";
    height: 14px;
    width: 14px;
    border-radius: 17px;
    background: #fff;
    top: 3px;
    left: 3px;
    transition-duration: 300ms;
    position: absolute;
    z-index: 1;
}
.switch-on {
    background: $cn_Blue;
}
.switch-on::after {
    left: 23px;
}