.ControlsAddressItem {
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: $g_Gut;
  }

  @include m('Highlighted') {
    & input {
      background-color: $cn_LightGreen;
      -webkit-transition: background-color 200ms linear;
      -ms-transition: background-color 200ms linear;
      transition: background-color 200ms linear;
    }
  }

  @include b('Address') {
    padding-left: 38px;
    & > input {
      padding: 0 $g_Mod-4 0 5px;
    }
  
    @include m('Geocoding') {
      & > input {
        background-color:  rgba(255, 193, 7, 0.42);
      }
    }
  
    @include m('Garage') {
      & > input {
        padding: 0 5px 0 5px;
      }
    }
  
    @include m('GeocodeFailed') {
      & > input {
        background-color: rgba(255, 87, 34, 0.42);
      }
    }
  }

  @include b('AddressList') {
    width: 100%;
    margin-bottom: $g_Gut;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @include b('PositionIcon') {
    position: absolute;
    pointer-events: none;
    top: 2px;
    left: 4px;
    height: 30px;
    width: 30px;
    fill: $cn_Blue;

    @include m('Geocoding') {
      fill: $cn_Orange;
    }

    @include m('GeocodeFailed') {
      fill: $cn_Red;
    }
  }

  @include b('EditButton') {
    position: absolute;
    cursor: pointer;
    top: 0;
    height: $g_Mod-2 + 4px;
    width: $g_Mod-2 + 4px;
    padding: 8px;
    fill: $cn_MediumGrey;

    &:hover {
      opacity: 0.5;
    }

    @include m('Remove') {
      right: 0;
    }

    @include m('WorkTime') {
      right: $g_Gut * 2;
    }

    @include m('WorkTimeSet') {
      fill: $cn_Blue;
      stroke: $cn_Blue;
      stroke-width: 8px;
    }
  }

  @include b('WorkTimeLabel') {
    display: block;
    margin: 4px 0;
    color: $cn_Blue;
    font-weight: bold;
  }

  @include b('WorkTime') {
    display: flex;
    
    & > * {
      margin-right: $g_Mod-0;
    }
  }

  @include b('FieldError') {
    text-align: right;
    color: $cn_Red;
    padding-top: 0.5em;
  }
}