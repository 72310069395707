.Toastify {
  @include b('toast-container') {
    pointer-events: none;
  }

  @include b('toast') {
    min-height: $g_Mod-4;
    margin-bottom: $g_Mod-0;
    padding: $g_Mod-0;
    border: 2px solid $cn_Blue;
    font-size: $fz_Big;
    color: $cn_Black;
    background: $cn_White !important;
    align-items: center;
    pointer-events: initial;

    &::before {
      content: '';
      height: $g_Gut; 
      width: $g_Gut;
      border-radius: $g_Gut; 
      margin-right: $g_Gut;
      margin-left: $g_Mod-0; 
    }

    @include dd('info') {
    }

    @include dd('success') {
      border: 2px solid $cn_Green;
      &::before {
        background-color: $cn_Green;
      }
    }
    
    @include dd('warning') {
      border: 2px solid $cn_Orange;
      &::before {
        background-color: $cn_Orange;
      }
    }

    @include dd('error') {
      border: 2px solid $cn_Red;
      &::before {
        background-color: $cn_Red;
      }
    }
  }
}